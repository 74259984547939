<template lang="pug">
div(v-if='data')
  //- We will show the page in Preview mode so we need to notify the user that it actually is password protected
  template(v-if='$storyblok.isPreview.value && content?.password')
    div(class='container bg-warning-light text-dark my-4 p-4') This content is password protected.
  //- If we are not in preview mode and not authenticated - show a login form
  template(v-else-if='!$storyblok.isPreview.value && data.requiresAuth')
    div(class='flex flex-col items-center w-full px-2 sm:p-0 sm:w-[400px] m-auto')
      h1 Enter Access Code
      BaseForm(ref='loginForm' class='w-full lg:w-[300px]' @submit='login')
        BaseFormInput(
          type='text'
          label='Access Code'
          name='accessCode'
          :is-required='true'
          default-error-text='Please enter the correct code.'
        )
        BaseButton(
          type='submit'
          label='Save'
          size='MEDIUM'
          :is-waiting='isSubmitting'
          :is-disabled='$storyblok.isEditing.value'
          class='pt-2 w-full'
        ) Submit
  CmsStoryblok(v-if='content' :sections='content.sections')
</template>

<script setup lang="ts">
const route = useRoute()
const { $storyblok, $timing, $speedcurve, $sitewideConfig } = useNuxtApp()
const { getApiUrl } = useUrls()
const { translateTemplate } = useTemplateTranslator()
const isSubmitting = ref(false)
const apiUrl = getApiUrl('cms')

// Image 404s were actually falling into this route!
definePageMeta({
  validate: (route) => {
    return isValidUrl(route.path)
  },
})

async function login(formData: any) {
  isSubmitting.value = true
  data.value = await getCmsStory(formData.accessCode)
  isSubmitting.value = false
}

const getCmsStory = async (password?: string) => {
  // Early out if not rendering a CMS Page
  if ($storyblok.isPreview.value && $storyblok.contentType.value !== 'cms-page') {
    // Still get the preview mode if this is Global Content
    if ($storyblok.contentType.value === 'global-content') return $storyblok.getPreviewStory()
    // early out for everyone else (example: Header)
    return null
  }

  let story: CmsStory | undefined
  interface Config {
    headers?: {
      Authorization: string
    }
  }
  try {
    // Try to get content data
    const slug = route.path.replace(/^\//, '')
    const timeTravel = route.query.timeTravel ? `?timeTravel=${route.query.timeTravel}` : ''
    const config: Config = {}

    if (password) {
      config.headers = { Authorization: password }
    }

    $timing.start('content-api')
    // if the slug is empty we pass 'home' to fix a catch-all routing issue with nitro
    const response = await $fetch<CmsStory>(`${apiUrl}${slug ? slug : 'home'}${timeTravel}`, config)
    $timing.end('content-api')

    // Make sure we have data (code defensively)
    if (!response?.story?.content || (response?.story?.content && Object.keys(response?.story?.content).length === 0)) {
      throw new Error('No CMS data found')
    }

    story = response
  } catch (error) {
    // Probably a 404
    if (!$storyblok.isPreview.value && !story) throw error
  } finally {
    const preview: CmsStory = (await $storyblok.getPreviewStory()) as CmsStory
    if (preview) story = preview
  }

  return story
}

// Handle Global Content
const { data, error } = await useAsyncData('content-data', () => getCmsStory())

if (data.value) updateStoryblokState(data.value)
if (error.value) {
  if (error.value.statusCode === 404) throw createError({ statusCode: 404, fatal: true })
  throw createError({ statusCode: 500, fatal: true, message: error.value.message })
}

function updateStoryblokState(story?: CmsStory) {
  if (!story) return
  $storyblok.initState(story)
}

onBeforeMount(() => {
  $speedcurve.track('CMS Page')
})

onMounted(() => {
  // Add a listener to update the content when in editor mode
  $storyblok.addListener((story) => {
    if ($storyblok.isCmsStory(story) || $storyblok.isGlobalContentStory(story)) data.value = story as CmsStory
  })
})

const content = computed(() => {
  return data?.value?.story?.content
})

interface schemaAuthors {
  '@type': string
  name: string
  url?: string
}
function getSchema() {
  if (!data?.value?.story) return '{}'

  const story = data.value.story
  const obj: {
    '@context': string
    '@type': string
    datePublished: string
    dateModified: string
    headline?: string
    image?: string
    author?: schemaAuthors[]
  } = {
    '@context': 'http://schema.org',
    '@type': 'Article',
    datePublished: story.first_published_at,
    dateModified: story.published_at,
  }

  if (story.content.shareImage?.filename) obj.image = story.content.shareImage.filename

  if (story.content.authors && story.content.authors.length > 0) {
    const authors: schemaAuthors[] = []
    story.content.authors.forEach((author: Author) => {
      authors.push({
        '@type': 'Person',
        name: author.name,
        ...(author.profileUrl?.cached_url && { url: appendTrailingSlash(author.profileUrl.cached_url) }),
      })
    })
    obj.author = authors
  }

  return JSON.stringify(obj)
}

const title = translateTemplate(content.value?.metaTitle || '')
const description = translateTemplate(content.value?.metaDescription || '')

function webPageSchema() {
  return {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: `https://${$sitewideConfig.domain}${route.path}`,
    headline: title,
    description,
    publisher: {
      '@type': 'Organization',
      name: $sitewideConfig.config.siteName,
    },
  }
}

useServerHead({
  script: [{ type: 'application/ld+json', innerHTML: webPageSchema(), tagPosition: 'bodyClose' }],
})

useMetaTags({
  title,
  description,
  isNoIndex: content.value?.disableSeoIndex === 'true',
  ...(content.value?.shareImage?.filename && {
    socialImageUrl: $storyblok.getNormalizedAsset(content.value.shareImage.filename, 1920)?.src || '',
  }),
})

const extraMetas = []
if (content.value?.metaLdJson) {
  const chunks = content.value?.metaLdJson.split('\n\n')
  for (let i = 0; i < chunks.length; i++) {
    extraMetas.push({ type: 'application/ld+json', innerHTML: chunks[i], tagPosition: 'bodyClose' })
  }
  useServerHead({
    script: extraMetas,
  })
}

useServerHead({
  ...(content.value?.hideHeaderFooter === 'true' && {
    style: [
      {
        innerHTML: '#Header, #Footer { display:none; }',
        key: 'hideHeaderFooter',
      },
    ],
  }),
  ...(content.value?.authors?.length !== 0 && {
    script: [{ type: 'application/ld+json', innerHTML: getSchema(), tagPosition: 'bodyClose' }],
  }),
})

useHead({
  ...(content.value?.hideHeaderFooter !== 'true' && {
    style: [
      {
        innerHTML: ' ', // An empty string apparently doesn't work
        key: 'hideHeaderFooter',
      },
    ],
  }),
  ...(content.value?.hideHeaderFooter === 'true' && {
    style: [
      {
        innerHTML: '#Header, #Footer { display:none; }',
        key: 'hideHeaderFooter',
      },
    ],
  }),
})
</script>
